import React, {useEffect, useState} from "react";
import SpechText from "../components/SpechText";
import FinishExam from "./FinishExam";
import {Link} from "react-router-dom";

var stringSimilarity = require("string-similarity");
const answerEmpty = require("./Quiz15Answer.json");

export default function Quiz15(props) {
    const [answer, setAnswer] = useState({...answerEmpty});
    const [pageNo, setPageNo] = useState(1);
    const [maxPage] = useState(5);

    const nextPage = () => {
        if (pageNo < maxPage - 1) setPageNo(pageNo + 1);
        // props.sendExam(answer,"QUIZ", false)
    };
    const prevPage = () => {
        if (pageNo > 0) setPageNo(pageNo - 1);
        // props.sendExam(answer,"QUIZ", false)
    };
    const [returnLink, setReturnLink] = useState("/mytasks");
    useEffect(() => {
        const isTeacher = window.location.href

        if (isTeacher.includes("teacher")) {
            setReturnLink("/teacher-contents/QUIZS")
            console.log(isTeacher)
        }
    }, [])
    const setStudentOpenAnswer = (
        skils,
        section,
        queationNumber,
        value,
        compare
    ) => {
        const temp = {...answer};
        temp.result[skils][section][queationNumber].student = value;
        let finalScore = temp.result[skils][section][queationNumber].weigth;

        var similarity = stringSimilarity.compareTwoStrings(
            clearText(value),
            clearText(temp.result[skils][section][queationNumber].answer)
        );
        finalScore =
            similarity *
            parseFloat(temp.result[skils][section][queationNumber].weigth);
        temp.result[skils][section][queationNumber].score = finalScore;

        if (
            finalScore >
            (temp.result[skils][section][queationNumber].weigth / 10) * 6
        ) {
            temp.result[skils][section][queationNumber].functionScore = true;
        } else {
            temp.result[skils][section][queationNumber].functionScore = false;
        }
        setAnswer(temp);
    };
    const getSpeechText = (text, questionNumber) => {
        setStudentOpenAnswer(0, 4, questionNumber, text, true);
    };

    const clearText = (text) => {
        let newText = text
            .replace(".", "")
            .replace("'", "")
            .replace("!", "")
            .replace(",", "")
            .replace("’", "")
            .replace("?", "")
            .replace("-", "")
            .replace("_", "");
        newText = newText.toLowerCase();
        return newText;
    };

    return (
        <div className="container">
            <div className="card mt-5 mb-5">
                <div className="card-header">
                    <div className="d-flex justify-content-center">
                        <h4>
                            <strong><Link to={returnLink}> RETURN TASK LIST</Link></strong>
                        </h4>
                    </div>
                </div>

                <div className="card-header">
                    <div className="d-flex justify-content-center">
                        <h1>
                            <strong> QUIZ CHAPTER 4 EPISODE 15</strong>
                        </h1>
                    </div>
                </div>
                <div className="card-body p-5">
                    <div>
                        {answer ? (
                            <React.Fragment>
                                <div style={{fontSize: "18pt"}}>
                                    {pageNo === 0 ? (
                                        <React.Fragment>
                                            <div className="alert alert-dark" role="alert">
                                                <strong> A. Choose the correct answer.</strong>
                                            </div>
                                            <div className="row">
                                                <strong>1. Choose the correct answer.</strong>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as1"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 1, "A")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        A) book
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as1"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 1, "B")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        B) mail
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as1"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 1, "C")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        C) food order
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as1"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 1, "D")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        D) SOS
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <strong>
                                                    2. The aid was requested by the ____________.
                                                </strong>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as2"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 2, "A")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        A) robots
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as2"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 2, "B")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        B) president
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as2"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 2, "C")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        C) headquarter
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as2"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 2, "D")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        D) police
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <strong>
                                                    3. They travel with ____________ to the head quarter.
                                                </strong>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as3"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 3, "A")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        A) Area C923
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as3"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 3, "B")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        B) Area C924
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as3"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 3, "C")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        C) Area C925
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as3"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 3, "D")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        D) Area C926
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <strong>
                                                    4. Dung beetle loves the vehicle because ____________
                                                    .
                                                </strong>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as4"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 4, "A")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        A) it is green
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as4"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 4, "B")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        B) it is soft
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as4"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 4, "C")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        C) it is round
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as4"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 4, "D")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        D) it smells
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <strong>5. Why did they send an SOS?</strong>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as5"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 5, "A")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        A) Trafic accident
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as5"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 5, "B")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        B) Energy shortage
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as5"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 5, "C")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        C) Health problem
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as5"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 5, "D")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        D) Disagreement
                                                    </label>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) : null}
                                    {pageNo === 1 ? (
                                        <React.Fragment>
                                            <div className="alert alert-dark" role="alert">
                                                <strong> B. Listen and fill in the gaps.</strong>
                                            </div>
                                            <div className="alert alert-dark" role="alert">
                                                <audio
                                                    controls
                                                    className="w-100"
                                                    style={{backgroundColor: "black", height: 45}}
                                                >
                                                    <source
                                                        src={`https://app.axebug.com/axebug/assets/ouiz_15_listening.mp3`}
                                                        type="audio/mpeg"
                                                    />
                                                </audio>
                                            </div>
                                            <div className="paragraf">
                                                <strong>AXEBUG:</strong> Light speed doesn’t change, no matter what.


                                            </div>
                                            <div className="paragraf">
                                                <strong>AXEBUG:</strong> It is


                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][1].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 1, e.target.value, true)
                                                    }
                                                />.


                                            </div>
                                            <div className="paragraf">
                                                <strong>AXEBUG: </strong>Light speed travels at 300.000km per

                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][2].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 2, e.target.value, true)
                                                    }
                                                />.

                                            </div>
                                            <div className="paragraf">
                                                <strong>DUNG BEETLE : </strong> What if we stabilized our speed to
                                                300.000km
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][3].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 3, e.target.value, true)
                                                    }
                                                />
                                                second then?
                                            </div>


                                            <div className="paragraf">
                                                <strong>LADYBUG:</strong> Time would idefinitely stop.
                                            </div>
                                            <div className="paragraf">
                                                <strong>DUNG BEETLE: </strong>Would it really
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][4].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 4, e.target.value, true)
                                                    }
                                                />
                                                ?
                                            </div>
                                            <div className="paragraf">
                                                <strong>LADYBUG: </strong>I mean, there won’t be time at all.
                                            </div>
                                            <div className="paragraf">
                                                <strong>AXEBUG:</strong> Or we would jump to another
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][5].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 5, e.target.value, true)
                                                    }
                                                />
                                                .
                                            </div>
                                            <div className="paragraf">
                                                <strong>DUNG BEETLE :</strong> Then…
                                            </div>
                                            <div className="paragraf">
                                                <strong>DUNG BEETLE : </strong>If time stops
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][6].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 6, e.target.value, true)
                                                    }
                                                />


                                                our travel in light speed…
                                            </div>
                                            <div className="paragraf">
                                                <strong>DUNG BEETLE :</strong> Is it possible to move
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][7].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 7, e.target.value, true)
                                                    }
                                                />
                                                than light?


                                            </div>
                                            <div className="paragraf">
                                                <strong>DUNG BEETLE :</strong> Can we travel in…
                                            </div>
                                            <div className="paragraf">
                                                <strong>LADYBUG:</strong> And?
                                            </div>
                                            <div className="paragraf">
                                                <strong>LADYBUG:</strong> A
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][8].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 8, e.target.value, true)
                                                    }
                                                />


                                                for your thoughts…
                                            </div>
                                            <div className="paragraf">
                                                <strong>DUNG:</strong> Just saying…
                                            </div>
                                            <div className="paragraf">
                                                <strong>DUNG BEETLE :</strong> Can we
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][9].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 9, e.target.value, true)
                                                    }
                                                />
                                                in time?
                                            </div>
                                            <div className="paragraf">
                                                <strong>DUNG BEETLE :</strong> I mean, time
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][10].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 10, e.target.value, true)
                                                    }
                                                />
                                                .
                                            </div>
                                            <div className="paragraf">
                                                <strong>AXEBUG:</strong> Yes!
                                            </div>

                                        </React.Fragment>
                                    ) : null}
                                    {pageNo === 2 ? (
                                        <React.Fragment>
                                            <div className="alert alert-dark" role="alert">
                                                <strong>
                                                    {" "}
                                                    C. Match the words with their definitions
                                                </strong>
                                            </div>

                                            <div className="row">
                                                <div className="col-2"> 1. Clarify</div>
                                                <div className="col-1">
                                                    <input
                                                        className="form-input w-100"
                                                        type="text"
                                                        value={answer.result[0][2][1].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(0, 2, 1, e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <div className="col-9">
                                                    <div className="paragraf alert alert-success">
                                                        <strong>A. </strong> A large, powerful light at the front of a
                                                        vehicle to illuminate the road ahead.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2"> 2. Headlights</div>
                                                <div className="col-1">
                                                    <input
                                                        className="form-input w-100"
                                                        type="text"
                                                        value={answer.result[0][2][2].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(0, 2, 2, e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <div className="col-9">
                                                    <div className="paragraf alert alert-success">
                                                        <strong>B. </strong> Although, while, on the contrary.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2"> 3. Time dilation</div>
                                                <div className="col-1">
                                                    <input
                                                        className="form-input w-100"
                                                        type="text"
                                                        value={answer.result[0][2][3].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(0, 2, 3, e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <div className="col-9">
                                                    <div className="paragraf alert alert-success">
                                                        <strong>C. </strong> C. To make something clear or easier to
                                                        understand, to define.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2"> 4. Whereas</div>
                                                <div className="col-1">
                                                    <input
                                                        className="form-input w-100"
                                                        type="text"
                                                        value={answer.result[0][2][4].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(0, 2, 4, e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <div className="col-9">
                                                    <div className="paragraf alert alert-success">
                                                        <strong>D. </strong> Not similar, unlike.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2"> 5. Difference</div>
                                                <div className="col-1">
                                                    <input
                                                        className="form-input w-100"
                                                        type="text"
                                                        value={answer.result[0][2][5].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(0, 2, 5, e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <div className="col-9">
                                                    <div className="paragraf alert alert-success">
                                                        <strong>E. </strong> Time differance difference caused by
                                                        gravitational forces and speed.
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) : null}
                                    {pageNo === 3 ? (
                                        <React.Fragment>
                                            <div className="alert alert-dark" role="alert">
                                                <strong> D. Unscramble the words.</strong>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 1. EDSPN</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][1].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                1,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 2. ODNIMESIN</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][2].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                2,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 3. UCREV</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][3].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                3,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 4. WKNON</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][4].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                4,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 5. UHSH</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][5].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                5,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 6. GIANATS</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][6].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                6,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 7. TOGFRE</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][7].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                7,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 8. SAMS</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][8].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                8,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 9. RACETIPL</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][9].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                9,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 10. GTSOH</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][10].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                10,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) : null}
                                    {pageNo === 4 ? (
                                        <React.Fragment>
                                            <div className="alert alert-dark" role="alert">
                                                <strong>
                                                    {" "}
                                                    A. Listen to the audio. Then, repeat the sentences
                                                    clearly.
                                                </strong>
                                            </div>
                                            <div className="alert alert-dark" role="alert">
                                                <audio
                                                    controls
                                                    className="w-100"
                                                    style={{backgroundColor: "black", height: 45}}
                                                >
                                                    <source
                                                        src={`https://app.axebug.com/axebug/assets/c4_e15_pg20_p5.mp3`}
                                                        type="audio/mpeg"
                                                    />
                                                </audio>
                                            </div>
                                            <div className="border border-success p-3 mt-3">
                                                <SpechText
                                                    getSpeechText={getSpeechText}
                                                    questionNumber="S1"
                                                />
                                            </div>
                                            <div className="alert alert-dark" role="alert">
                                                <audio
                                                    controls
                                                    className="w-100"
                                                    style={{backgroundColor: "black", height: 45}}
                                                >
                                                    <source
                                                        src={`https://app.axebug.com/axebug/assets/c4_e15_pg20_p6.mp3`}
                                                        type="audio/mpeg"
                                                    />
                                                </audio>
                                            </div>
                                            <div className="border border-success p-3 mt-3">
                                                <SpechText
                                                    getSpeechText={getSpeechText}
                                                    questionNumber="S2"
                                                />
                                            </div>
                                            <div className="alert alert-dark" role="alert">
                                                <audio
                                                    controls
                                                    className="w-100"
                                                    style={{backgroundColor: "black", height: 45}}
                                                >
                                                    <source
                                                        src={`https://app.axebug.com/axebug/assets/c4_e15_pg20_p15.mp3`}
                                                        type="audio/mpeg"
                                                    />
                                                </audio>
                                            </div>
                                            <div className="border border-success p-3 mt-3">
                                                <SpechText
                                                    getSpeechText={getSpeechText}
                                                    questionNumber="S3"
                                                />
                                            </div>
                                            <div className="alert alert-dark" role="alert">
                                                <audio
                                                    controls
                                                    className="w-100"
                                                    style={{backgroundColor: "black", height: 45}}
                                                >
                                                    <source
                                                        src={`https://app.axebug.com/axebug/assets/c4_e15_pg21_p7_8.mp3`}
                                                        type="audio/mpeg"
                                                    />
                                                </audio>
                                            </div>
                                            <div className="border border-success p-3 mt-3">
                                                <SpechText
                                                    getSpeechText={getSpeechText}
                                                    questionNumber="S4"
                                                />
                                            </div>
                                        </React.Fragment>
                                    ) : null}
                                    {pageNo === 5 ? <FinishExam/> : null}
                                </div>
                            </React.Fragment>
                        ) : null}
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row">
                        {pageNo !== 0 ? (
                            <div className="col-auto">
                                <button className="btn btn-success" onClick={() => prevPage()}>
                                    PREV
                                </button>
                            </div>
                        ) : null}
                        {pageNo !== maxPage - 1 ? (
                            <div className="col-auto pl-2">
                                <button className="btn btn-success" onClick={() => nextPage()}>
                                    NEXT
                                </button>
                            </div>
                        ) : null}
                        {pageNo === maxPage - 1 ? (
                            <div className="col-auto pl-2">
                                <button
                                    className="btn btn-success"
                                    onClick={() => props.sendExam(answer, "QUIZ", true)}
                                >
                                    FINISH EXAM
                                </button>
                            </div>
                        ) : null}
                        {/* <div className="col pl-4">
              <button className='btn btn-info pl-4' onClick={() => props.sendExam(answer,"QUIZ", false)}>SAVE EXAM</button>
            </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
