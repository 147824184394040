import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Square from '../app/components/Square'
import useDocument from '../api/useDocument';
import useTask from '../api/useTask';
import {useParams} from 'react-router-dom';
import useExam from '../api/useExam';
import Axios from 'axios';

var fileDownload = require('js-file-download');

export default function TeacherContents() {
    const [documents, setDocuments] = useDocument([]);
    const [exams, setExams] = useExam([]);
    const [open, setOpen] = useState(true);
    const [tasks, setTasks] = useTask([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [linkType, setLinkType] = useState("");

    let {id} = useParams();

    useEffect(() => {
        setDocuments("findAllDocuments", "")
        setExams("findAllExam", "")
        setTasks("findAllTasks", "")
    }, [])

    const download = (url, filename) => {
        Axios.get(url, {
            responseType: 'blob',
        }).then(res => {
            fileDownload(res.data, filename);
        });
    }

    useEffect(() => {
        if (id && documents) {
            setOpen(false)
            const activeList = [];
            if (id === "TASKS") {
                if (tasks)
                    tasks.forEach(element => {
                        activeList.push(element)
                    });
                setLinkType("TASKS")
            } else if (id === "COMICS") {
                setLinkType("COMICS")
            } else if (id === "ACTIVTY") {
                setLinkType("ACTIVTY")
            } else if (id === "CONNECTION") {
                setLinkType("CONNECTION")
            } else if (id === "EXAMS") {
                setLinkType("EXAMS")
            } else if (id === "QUIZS") {
                setLinkType("QUIZS")
            } else {
                documents.sort(function (a, b) {
                    return a.id - b.id;
                });
                documents.forEach(element => {
                    if (element.type === id) {
                        activeList.push(element)
                    }
                });
                setLinkType("OUT")
            }
            setSelectedDocuments(activeList)
        } else {
            setOpen(true)
        }
    }, [id, documents])

    //YEARLY_PLAN
    //DAILY_PLAN
    //COMICS
    //GAME
    //PLAY
    //SHOW_TIME
    //ACTIVITY

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-12 mt-4'>
                    <div className='row'>
                        <div className='col-12'>
                            <div
                                className="text-white bg-dark border border-2 border-dark p-2 mt-3 d-flex justify-content-center"
                                style={{width: "100%"}}><h2><b> <Link to="/teacher" style={{
                                color: "white",
                                textDecoration: "none"
                            }}> AXEBUG DIGITAL</Link></b></h2></div>
                        </div>
                        {/* <div className='col-12'>
              <div className="border border-2 border-dark p-2 mt-3 d-flex justify-content-center" style={{ width: "100%" }}><h2><b>Teacher's Contents</b></h2></div>
            </div> */}
                    </div>
                </div>
                {
                    open
                }
                {
                    open === true ?
                        <div className='col-12 mt-3'>
                            <div className='row p-0'>
                                <Square col="2" backgroundColor="black" to="/teacher"
                                        style={{cursor: "pointer"}}><b><span
                                    style={{cursor: "pointer"}}>MAIN PAGE</span></b> </Square>
                            </div>
                            <div className='row'>
                                <Square col="2" backgroundColor="black"><b><span>PLANNING</span></b> </Square>
                                <Square col="2" backgroundColor="white" to="/teacher-contents/YEARLY_PLAN"><b><span>YEARLY PLAN</span></b>
                                </Square>
                                <Square col="2" backgroundColor="white" to="/teacher-contents/DAILY_PLAN"><b><span>LESSON PLAN</span></b>
                                </Square>
                            </div>
                            <div className='row'>
                                <Square col="2" backgroundColor="black"><b><span>ASSESSMENT</span></b> </Square>
                                <Square col="2" backgroundColor="white"
                                        to="/teacher-contents/EXAMS"><b><span>EXAMS</span></b> </Square>
                                <Square col="2" backgroundColor="white"
                                        to="/teacher-contents/QUIZS"><b><span>QUIZZES</span></b> </Square>
                            </div>
                            <div className='row'>
                                <Square col="2" backgroundColor="black"><b><span>CONTENTS</span></b> </Square>
                                <Square col="2" backgroundColor="white" to="/teacher-contents/COMICS"><b><span>COMIC BOOKS</span></b>
                                </Square>
                                <Square col="2" backgroundColor="white" to="/teacher-contents/ACTIVTY"><b><span>ACTIVITY BOOKS</span></b>
                                </Square>
                                <Square col="2" backgroundColor="white" to="/teacher-contents/CONNECTION"><b><span>SCIENCE & MATH</span></b>
                                </Square>
                                <Square col="2" backgroundColor="white"
                                        to="/teacher-contents/TASKS"><b><span>TASKS</span></b>
                                </Square>
                            </div>

                            <div className='row mb-5'>
                                <Square col="2" backgroundColor="black"><b><span>APPLICATION</span></b> </Square>
                                <Square col="2" backgroundColor="white"
                                        to="/teacher-spelling-list"><b><span>SPELLING</span></b> </Square>
                                <Square col="2" backgroundColor="white"
                                        to="/teacher-wordbank-list"><b><span>WORD BANK</span></b> </Square>
                                <Square col="2" backgroundColor="white"
                                        to="/teacher-contents/GAME"><b><span>CLASS GAMES</span></b> </Square>
                                <Square col="2" backgroundColor="white"
                                        to="/teacher-contents/PLAY"><b><span>PLAYS</span></b> </Square>
                                <Square col="2" backgroundColor="white" to="/teacher-contents/SHOW_TIME"><b><span>SHOW TIME</span></b>
                                </Square>
                            </div>
                        </div>
                        :
                        <div className='col-12 mt-3'>
                            <div className='row'>

                                <Square col="2" backgroundColor="black" to="/teacher"
                                        style={{cursor: "pointer"}}><b><span
                                    style={{cursor: "pointer"}}>MAIN PAGE</span></b> </Square>
                                <Square col="2" backgroundColor="black" to="/teacher-contents"
                                        style={{cursor: "pointer"}}><b><span style={{cursor: "pointer"}}>BACK</span></b>
                                </Square>
                            </div>
                            <div className='row'>

                                {
                                    linkType === "OUT" ?
                                        selectedDocuments ?
                                            selectedDocuments.map((document, key) =>
                                                (id === "YEARLY_PLAN" && key < 2) ||
                                                (id === "DAILY_PLAN" && key < 16) ||
                                                (id === "COMICS" && key < 2) ||
                                                (id === "GAME" && key < 2) ||
                                                (id === "PLAY" && key < 4) ||
                                                (id === "SHOW_TIME" && key < 2) ||
                                                (id === "ACTIVITY" && key < 2)
                                                    ?
                                                    <Square key={key} col="2" backgroundColor="white">
                                                        <span>
                                                            <b>
                                                          <a className='btn btn-success' target="_blank"
                                                             href={`https://app.axebug.com/axebug/pdf/${document.link}`}>
                                                            <span>{document.name}</span>
                                                          </a>
                                                            </b>
                                                        </span>
                                                    </Square> : null
                                            ) : null
                                        : null
                                }
                                {
                                    linkType === "TASKS" ?
                                        selectedDocuments ?
                                            selectedDocuments.map((document, key) =>
                                                key < 16 ?
                                                    <Square key={key} col="2" backgroundColor="white"
                                                            to={`/teacher-tasks/${document.id}`}><b>{document.name}</b>
                                                    </Square> : null
                                            ) : null
                                        : null
                                }
                                {
                                    linkType === "EXAMS" ?
                                        exams ?
                                            exams.map((document, key) =>

                                                document.examType === "EXAM" ?
                                                    key < 2 ?
                                                        <Square key={key} col="2" backgroundColor="white"
                                                                to={`/teacher-exam/${document.id}`}><b>{document.name}</b>
                                                        </Square> : null
                                                    : null
                                            ) : null
                                        : null
                                }
                                {
                                    linkType === "QUIZS" ?
                                        exams ?
                                            exams.map((document, key) =>
                                                document.examType === "QUIZ" ?
                                                    key < 12 ?
                                                        <Square key={key} col="2" backgroundColor="white"
                                                                to={`/teacher-exam/${document.id}`}><b>{document.name}</b>
                                                        </Square> : null
                                                    : null
                                            ) : null
                                        : null
                                }
                                {
                                    linkType === "COMICS" ?
                                        <React.Fragment>
                                            <div className="col-3 p-2"><a target="_blank"
                                                                          href="https://madeinbrain.net/axebug/HTML/Chapter1/index.html"><img
                                                className='w-100' src={`https://app.axebug.com/axebug/assets/ch1.png`}/></a>
                                            </div>


                                            <div className="col-3 p-2"><a target="_blank"
                                                                          href="https://madeinbrain.net/axebug/HTML/Chapter2/index.html"><img
                                                className='w-100' src={`https://app.axebug.com/axebug/assets/ch2.png`}/></a>
                                            </div>

                                            {
                                                // <div className="col-3 p-2"><a target="_blank" href="https://madeinbrain.net/axebug/HTML/Chapter3/index.html"><img className='w-100' src={`https://app.axebug.com/axebug/assets/ch3.png`}/></a></div>
                                            }
                                            {
                                                // <div className="col-3 p-2"><a target="_blank" href="https://madeinbrain.net/axebug/HTML/Chapter4/index.html"><img className='w-100' src={`https://app.axebug.com/axebug/assets/ch4.png`}/></a></div>
                                            }

                                        </React.Fragment>
                                        : null
                                }
                                {
                                    linkType === "ACTIVTY" ?
                                        <React.Fragment>

                                            <div className="col-3 p-2"><a target="_blank"
                                                                          href="https://madeinbrain.net/axebug/HTML/Activite1/index.html"
                                                                          rel="noreferrer"><img className='w-100'
                                                                                                src={`https://app.axebug.com/axebug/assets/ab1.png`}/></a>
                                                <a className='mt-2 border border-success p-2 d-flex justify-content-center'
                                                   target="_blank"
                                                   href={`https://madeinbrain.net/pdf/Activite_1_Book_key.pdf`}
                                                   rel="noreferrer">
                                                    <span>DOWNLOAD KEY</span>
                                                </a>
                                            </div>

                                            <div className="col-3 p-2"><a target="_blank"
                                                                          href="https://madeinbrain.net/axebug/HTML/Activite2/index.html"
                                                                          rel="noreferrer"><img className='w-100'
                                                                                                src={`https://app.axebug.com/axebug/assets/ab2.png`}/></a>
                                                <a className='mt-2 border border-success p-2 d-flex justify-content-center'
                                                   target="_blank"
                                                   href={`https://madeinbrain.net/pdf/Activite_2_Book_key.pdf`}
                                                   rel="noreferrer">
                                                    <span>DOWNLOAD KEY</span>
                                                </a>
                                            </div>

                                            {
                                                // <div className="col-3 p-2"><a target="_blank" href="https://madeinbrain.net/axebug/HTML/Activite3/index.html" rel="noreferrer"><img className='w-100' src={`https://app.axebug.com/axebug/assets/ab3.png`}/></a></div>
                                            }
                                            {
                                                // <div className="col-3 p-2"><a target="_blank" href="https://madeinbrain.net/axebug/HTML/Activite4/index.html" rel="noreferrer"><img className='w-100' src={`https://app.axebug.com/axebug/assets/ab4.png`}/></a></div>
                                            }

                                        </React.Fragment>
                                        : null
                                }
                                {
                                    linkType === "CONNECTION" ?
                                        <React.Fragment>
                                            <div className="col-3 p-2"><a target="_blank"
                                                                          href="https://madeinbrain.net/axebug/HTML/Mathematics/index.html"
                                                                          rel="noreferrer"><img className='w-100'
                                                                                                src={`https://app.axebug.com/axebug/assets/math.png`}/></a>
                                            </div>
                                            <div className="col-3 p-2"><a target="_blank"
                                                                          href="https://madeinbrain.net/axebug/HTML/Science/index.html"
                                                                          rel="noreferrer"><img className='w-100'
                                                                                                src={`https://app.axebug.com/axebug/assets/science.png`}/></a>
                                            </div>
                                        </React.Fragment>
                                        : null
                                }
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}
